import { Outlet, useRoutes } from "react-router-dom";
import { lazy, Suspense } from "react";
import React, { useState, useEffect } from 'react';



// import { Dashboard, SharedLayouts } from "../pages/dashboard";
import { DashboardLayout } from "../Layouts";
import { DashboardLayoutTeam } from "../Layouts/TeamLayouts";
import { Reset, Login, Signup, ResetPassword } from "../pages/auth";
import SignUpSuccess from "../pages/auth/SignUpSuccess";
import ResetSuccess from "../pages/auth/ResetSucess";
import ProtectedRoute from "./ProtectedRoute";
import TeamLogin from "../pages/auth/TeamLogin";
import NotFound from "../pages/NotFound";
import TeamInvoice from "../TeamDashboard/TeamInvoice";

export const IndexPage = lazy(() => import("../pages/dashboard/Dashboard"));
export const Reservation = lazy(() =>
  import("../pages/dashboard/Reservations")
);
export const Orders = lazy(() => import("../pages/dashboard/PendingOrders"));
export const Menu = lazy(() => import("../pages/dashboard/OurMenu"));
export const EventManagement = lazy(() =>
  import("../pages/dashboard/EventManagament")
);
export const EventDetails = lazy(() =>
  import("../pages/dashboard/EventDetails")
);
export const Profile = lazy(() => import("../pages/dashboard/Profile"));
export const Invoice = lazy(() => import("../pages/dashboard/Invoice"));

export const ChangePassword = lazy(() =>
  import("../components/Profile/pages/ChangePassword")
);
export const HouseRules = lazy(() =>
  import("../components/Profile/pages/HouseRules")
);
export const OnlineReservation = lazy(() =>
  import("../components/Profile/pages/OnlineReservation")
);
export const OpenHours = lazy(() =>
  import("../components/Profile/pages/OpenHours")
);
export const ProfileDetails = lazy(() =>
  import("../components/Profile/pages/ProfileDetails")
);
export const Revenue = lazy(() =>
  import("../components/Profile/pages/Revenue")
);
export const Taxes = lazy(() => import("../components/Profile/pages/Taxes"));
export const Team = lazy(() => import("../components/Profile/pages/Team"));
export const TeamDashboard = lazy(() =>
  import("../TeamDashboard/TeamDashboard")
);

const useIsActive = () => {
  const [isActive, setIsActive] = useState(() => {
    // Initialize state from localStorage
    const vendorInfo = JSON.parse(window.localStorage.getItem('vendorInfo2') || '{}');
    return vendorInfo?.is_active !== 'false'; // Default to true if not found
  });

  useEffect(() => {
    const handleStorageChange = () => {
      const vendorInfo = JSON.parse(window.localStorage.getItem('vendorInfo2') || '{}');
      setIsActive(vendorInfo?.is_active !== 'false');
    };

    // Listen for changes to localStorage
    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return isActive;
};

export default function Routes() {

  let element = useRoutes([
    {
      element: (
        <ProtectedRoute>
          {" "}
          <DashboardLayout>
            <Suspense>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </ProtectedRoute>
      ),
      children: [
        {
          index: true,
          element:  !useIsActive() ? <EventManagement/> :  <IndexPage />,
        },
        {
          path: "reservation",
          element: <Reservation />,
        },
        {
          path: "pending-orders",
          element: <Orders />,
        },
        {
          path: "our-menu",
          element: <Menu />,
        },
        {
          path: "event-management",
          element: <EventManagement />,
        },
        {
          path: "event-details/:id",
          element: <EventDetails />,
        },
        {
          element: (
            <ProtectedRoute>
              {" "}
              <Profile />
            </ProtectedRoute>
          ),
          children: [
            {
              path: "profile",
              element: <Revenue />,
            },

            {
              path: "profile/change-password",
              element: <ChangePassword />,
            },
            {
              path: "profile/profile-details",
              element: <ProfileDetails />,
            },
            {
              path: "profile/online-reservation",
              element: <OnlineReservation />,
            },
            {
              path: "profile/open-hours",
              element: <OpenHours />,
            },
            {
              path: "profile/team",
              element: <Team />,
            },
            {
              path: "profile/taxes",
              element: <Taxes />,
            },
            {
              path: "profile/house-rules",
              element: <HouseRules />,
            },
          ],
        },
        {
          path: "generate-invoice",
          element: <Invoice />,
        },
      ],
    },
    {
      element: (
        <ProtectedRoute>
          {" "}
          <DashboardLayoutTeam>
            <Suspense>
              <Outlet />
            </Suspense>
          </DashboardLayoutTeam>
        </ProtectedRoute>
      ),
      children: [
        {
          path: "/team-dashboard",
          element: <TeamDashboard />,
        },
        {
          path: "/team-generateInvoice",
          element: <TeamInvoice />,
        },
      ],
    },

    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/forgot-password",
      element: <Reset />,
    },
    {
      path: "/reset-password/:id",
      element: <ResetPassword />,
    },
    {
      path: "/signup/:id",
      element: <Signup />,
    },
    {
      path: "/signed-up",
      element: <SignUpSuccess />,
    },
    {
      path: "/reset/:variant",
      element: <ResetSuccess />,
    },
    {
      path: "/login/team",
      element: <TeamLogin />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);

  return element;
}
