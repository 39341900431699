import React, { useState, useEffect } from "react";
import { usePathname } from "../hooks/use-pathname";
import { Box, Typography, ListItemButton } from "@mui/material";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import RouterLink from "../hooks/router-link";

import bgImg from "../assets/img/Rectangle 152158.jpg";
import logo from "../assets/logo/single.svg";
import Faqs from "../pages/dashboard/Faqs";
import { getPendingOrders } from "../api";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import NavConfig from "./config-Navigation";

const Nav = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const handleGetPendingOrders = async () => {
    setIsLoading(true);
    await getPendingOrders()
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.status) {
          localStorage.setItem(
            "pending_order_count",
            res?.data?.data?.order_count
          );
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    handleGetPendingOrders();
  }, []);

  useEffect(() => {
    const get_count = localStorage.getItem("pending_order_count");

    setCount(get_count);
  }, []);
  const navConfig = NavConfig();

  return (
    <>
      <Box
        sx={{
          backgroundImage: `url('${bgImg}')`,
          height: "100vh",
          width: "242px",
          backgroundSize: "cover",
          backgroundPosition: "bottom",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box sx={{ mt: 6 }}>
          <img src={logo} />
        </Box>
        <Stack component="nav" spacing={3} sx={{ px: 2, mt: 7 }}>
          {navConfig.map((item) => (
            <NavItem
              key={item.title}
              item={item}
              count={count}
              isLoading={isLoading}
            />
          ))}
          <Box>
            <Faqs />
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default Nav;

// ----------------------------------------------------------------------

function NavItem({ item, count, isLoading }) {
  const pathname = usePathname();

  const active = item.path === pathname || pathname.includes(item.path2);

  return (
    <ListItemButton
      // disabled={item?.isDelisted}
      component={RouterLink}
      href={`${item.path}`}
      sx={{
        minHeight: 44,
        borderRadius: 0.75,
        typography: "body2",
        color: "#8F8F8F",
        textTransform: "capitalize",
        fontWeight: "fontWeightMedium",
        display: item?.isDelisted ? "none" : "flex",
        alignItems: "center",
        ...(active && {
          color: "#F489FD",
          fontWeight: "fontWeightSemiBold",
          bgcolor: "",
          "&:hover": {
            bgcolor: "",
          },
        }),
      }}
    >
      <Box component="span" sx={{ width: 24, height: 24, mr: 2 }}>
        {active ? item.icon2 : item.icon}
      </Box>

      <Box
        component="span"
        sx={{ display: "flex", alignItems: "center", columnGap: 1 }}
      >
        {item.title}{" "}
        {item?.count && (
          <Box>
            {isLoading ? (
              <>
                <svg width={0} height={0}>
                  <defs>
                    <linearGradient
                      id="my_gradient"
                      x1="0%"
                      y1="0%"
                      x2="0%"
                      y2="100%"
                    >
                      <stop offset="0%" stopColor="#e01cd5" />
                      <stop offset="100%" stopColor="#1CB5E0" />
                    </linearGradient>
                  </defs>
                </svg>
                <CircularProgress
                  sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}
                  size={20}
                />
              </>
            ) : (
              <Box
                sx={{
                  bgcolor: "#75007E",
                  color: "#fff",
                  minWidth: "16px",
                  minHeight: "16px",
                  display: "grid",
                  placeItems: "center",
                  fontSize: "10px",
                  borderRadius: "50%",
                }}
              >
                {count}
              </Box>
            )}
          </Box>
        )}
      </Box>
    </ListItemButton>
  );
}

NavItem.propTypes = {
  item: PropTypes.object,
};
