
import React, { useState, useEffect } from 'react';
import icon1 from '../assets/icons/nav/1.svg';
import icon2 from '../assets/icons/nav/2.svg';
import icon3 from '../assets/icons/nav/3.svg';
import icon4 from '../assets/icons/nav/4.svg';
import icon5 from '../assets/icons/nav/5.svg';
import icon6 from '../assets/icons/nav/6.svg';
import icon14 from '../assets/icons/nav/8.svg';
import icon7 from '../assets/icons/nav/active/1.svg';
import icon8 from '../assets/icons/nav/active/2.svg';
import icon9 from '../assets/icons/nav/active/3.svg';
import icon10 from '../assets/icons/nav/active/4.svg';
import icon11 from '../assets/icons/nav/active/5.svg';
import icon12 from '../assets/icons/nav/active/6.svg';
import icon13 from '../assets/icons/nav/active/8.svg';

export const useIsActive = () => {
  const [isActive, setIsActive] = useState(() => {
    // Initialize state from localStorage
    const vendorInfo = JSON.parse(window.localStorage.getItem('vendorInfo2') || '{}');
    return vendorInfo?.is_active !== 'false'; // Default to true if not found
  });

  useEffect(() => {
    const handleStorageChange = () => {
      const vendorInfo = JSON.parse(window.localStorage.getItem('vendorInfo2') || '{}');
      setIsActive(vendorInfo?.is_active !== 'false');
    };

    // Listen for changes to localStorage
    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return isActive;
};

const NavConfig = () => {
  const isDelisted = !useIsActive(); // Dynamically update based on state

  const navConfig = [

    {
        title: 'dashboard',
        path: '/',
        icon:<img src={icon1}/>,
        icon2:<img src={icon7}/>,
        isDelisted 
    },
    {
        title:'Reservations',
        path:'/reservation',
        icon:<img src={icon2}/>,
        icon2:<img src={icon8}/>,
        isDelisted
    },
    {
        title:'Pending Orders',
        path:'/pending-orders',
        icon:<img src={icon3}/>,
        icon2:<img src={icon9}/>,
        count:true,
        isDelisted
    },
    {
        title:'Our Menu',
        path:'/our-menu',
        icon:<img src={icon4}/>,
        icon2:<img src={icon10}/>,
        isDelisted
    },
    {
        title:'Profile',
        path:'/profile',
        path2:'profile',
        icon:<img src={icon5}/>,
        icon2:<img src={icon11}/>,

    },
    // {
    //     title:'Generate Invoice',
    //     path:'/generate-invoice',
    //     icon:<img src={icon6}/>,
    //     icon2:<img src={icon12}/>
    // },
    {
        title:'Events Management',
        path:'/event-management',
        icon:<img src={icon14}/>,
        icon2:<img src={icon13}/>,

    },
  ];

  return navConfig;
};

export default NavConfig;

