import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import AuthLayout from "./AuthLayout";
import CustomInput from "../../components/Custom/CustomInput";
import { Link, useLocation } from "react-router-dom";
import CustomOtp from "../../components/Custom/CustomOtp";
import { login } from "../../api";
import Loader from "../../components/common/Loader";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUser } from "../../store/userSlice";
import { useSnackbar } from "notistack";
import SEO from "../../components/SEO";
import { useIsActive } from "../../Layouts/config-Navigation";


const Login = () => {
  const [email, setEmail] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [otp, setOtp] = React.useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isActive = useIsActive()

  const location = useLocation()

    const locationState = location.state;

    const from = location.state?.from?.pathname || "/";

  const handleAlert = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  const handleChange = (newValue) => {
    setError(false);
    setOtp(newValue);
  };

  useEffect(() => {
    if (otp.length === 6) {
      handleLogin();
    }
  }, [otp]);

  const handleLogin = async () => {
    setLoading(true);
    setError(false);
    await login(email, otp)
      .then((res) => {
        setLoading(false);
        const { data } = res;
        if (data.status) {
          localStorage.setItem("vendorInfo", JSON.stringify(data?.data?.token));
          localStorage.setItem("vendorInfo2", JSON.stringify(data?.data));
          dispatch(setUser(data?.data));
            navigate(from, { replace: true });
        if (locationState && locationState.redirectTo) {
          navigate(`${locationState?.redirectTo}`);
        } else {

          navigate(data?.data?.is_active === "false"  ? "/event-management": "/");
        }
        }
        else{
           setError(true);

               handleAlert(`${res?.data?.message}`, "error");
        }
      })
      .catch((err) => {

        setLoading(false);
        handleAlert(`${err.response.data?.message}`, "error");
        setError(true);
      });
  };
  return (
    <>
        <SEO
        title="VibezsUp Vendor App | Login Page"
        description="Leverage our Supercharged Vendor App for Managing Genrated Leads, Reservation, Menu & Profile Information"
        name="Vibezsup"
        type="article"
      />
      <AuthLayout>
        {loading && <Loader />}

        <Box sx={{ width: {lg:"70%", md:'70%', sm:'100%', xs:'100%'} }}>
          <Typography
            sx={{
              fontFamily: "Butler",
              color: "#fff",
              fontSize: {lg:"24px", md:'24px', sm:'20px', xs:'20px'},
              fontWeight: "bolder",
            }}
          >
            Welcome Back
          </Typography>
          <Typography variant="body1" sx={{ mt: {lg:3, md:3, sm:2, xs:1}, color:'#fff' }}>
            Enter your email & password to Login
          </Typography>
          <Box sx={{ mt: 5 }}>
            {error ? (
              <Typography variant="body1" sx={{ color: "#EA8072" ,  fontSize:{lg:'14px', md:'14px', sm:'12px', xs:'12px'}}}>
                Email (Incorrect User details)
              </Typography>
            ) : (
              <Typography variant="body1" sx={{ fontSize:{lg:'14px', md:'14px', sm:'12px', xs:'12px'}, color:'#fff'}}>Email</Typography>
            )}
            <CustomInput
              error={error}
              margin="dense"
              fullWidth
              placeholder="Enter your registered email"
              onChange={(e) => setEmail(e.target.value)}
            />
            {error ? (
              <Typography variant="body1" sx={{ mt: 3, color: "#EA8072",  fontSize:{lg:'14px', md:'14px', sm:'12px', xs:'12px'} }}>
                Password (Incorrect User details)
              </Typography>
            ) : (
              <Typography variant="body1" sx={{ mt: 3,  fontSize:{lg:'14px', md:'14px', sm:'12px', xs:'12px'}, color:'#fff' }}>
                Password
              </Typography>
            )}

            <Box sx={{ mt: 1 , overflow:'hidden', width:'100%', }}>
              <CustomOtp
                otp={otp}
                light={false}
                handleChange={handleChange}
                error={error}
                type={true}
              />
            </Box>
          </Box>

          <Box sx={{ mt: 4 }}>
            <Button
     
              onClick={handleLogin}
              disabled={otp.length < 6 || !email}
              variant="contained"
              sx={{
                height: "63px",
                width: {lg:"300px", md:'300px', sm:'100%', xs:'100%'},
                borderRadius: "10px",
                "&:disabled": { background: "#5b5b5b", color: "#fff" },
              }}
            >
              {" "}
              Login{" "}
            </Button>
          </Box>
          <Box sx={{ mt: 3, width: "fit-content" }}>
            <Link to="/forgot-password">
              <Typography
                variant="body1"
                sx={{ color: "#F489FD", textDecoration: "underline",  fontSize:{lg:'14px', md:'14px', sm:'12px', xs:'12px'} }}
              >
                Password Reset
              </Typography>
            </Link>
            <Link to="/login/team" style={{ width: "fit-content" }}>
              <Typography
                variant="body1"
                sx={{
                  color: "#F489FD",
                  textDecoration: "underline",
                  mt: 2,
                  width: "fit-content",
                   fontSize:{lg:'14px', md:'14px', sm:'12px', xs:'12px'}
                }}
              >
                Team Member Login
              </Typography>
            </Link>
          </Box>
        </Box>
      </AuthLayout>
    </>
  );
};

export default Login;
